import axios from "../core/axios";
import { assertSuccess } from "../core/assert";
import type { TagEntity } from "../types/tag";
import type { CollectionResult } from "../types/result";

export class Tag {
  static async getTagList() {
    const resp = await axios<CollectionResult<TagEntity>>(`/title/tags`, {
      responseType: "json",
    });

    if (resp.status !== 200)
      throw new Error(
        "There was an error while attempting to get all title tags.",
      );

    return assertSuccess(resp.data);
  }
}
